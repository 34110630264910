import React from 'react';
import './Main.scss';
import Box from '../Box/Box';
import List from '../List/List';
import TextItem from '../../components/TextItem/TextItem';
import Header from '../../components/Header/Header';
import Button from '../../components/Button/Button';

import monitoring from '../../../assets/svg/monitoring.svg';
import abstraction from '../../../assets/svg/abstraction.svg';
import quickSetup from '../../../assets/svg/quick-setup.svg';
import license from '../../../assets/svg/license.svg';
import techStack from '../../../assets/svg/technology-stack.svg';
import artLogo from '../../../assets/svg/artezio-logo.svg';
import process from '../../../assets/svg/process.svg';
import github from '../../../assets/svg/github.svg';
import {Col, Row} from 'reactstrap';

const Main = () => (
    <>
        <Box className='header' paddingTop='20px' paddingBottom='20px'>
            <Header/>
        </Box>
        <Box className='headline text-center text-md-left' paddingTop='80px' paddingBottom='100px'>
            <Headline/>
        </Box>
        <Box className='benefits-headline' bgColor='gray'>
            <BenefitsHeadline/>
        </Box>
        <Box className='benefits-list text-center text-md-left' paddingTop='0px' paddingBottom='100px'>
            <BenefitsList/>
        </Box>
        <Box className='features' bgColor='gray'>
            <Features/>
        </Box>
        <Box className='info text-center text-md-left' paddingTop='0' paddingBottom='0'>
            <Info/>
        </Box>
        <Box className='footer' bgColor='blue' paddingTop='20px' paddingBottom='16px'>
            <Footer/>
        </Box>
    </>
);

const Headline = () => (
    <Row>
        <Col md={6} xs={12} className="align-self-center">
            <h1 className="title">A free robust guaranteed delivery service bus layer for your applications.</h1>
            <p>Guaranteed Delivery Layer is an open source and fully scalable solution helping to decrease the time required for data transfer and development in general.</p>
            <Button icon={github} onClick={() => window.open('https://github.com/Artezio/ART-GDL')} value='Download from GitHub'/>
        </Col>
        <Col md={{size: 6, offset: 0}} xs={{size:6, offset: 3}}>
            <img className="img-fluid d-none d-md-block" src={process} alt="process"/>
        </Col>
    </Row>
);

const BenefitsHeadline = () => (
    <section id='whatis'>
        <h1 className='benefits__header mx-auto'>What is Guaranteed Delivery Layer?</h1>
        <p className='benefits__content mx-auto'>Guaranteed Delivery Layer is a Java library working as a part of the framework for integrated applications
            development Apache Camel. It is designed for enhancing Apache Camel functionality in EIP Guaranteed Delivery realization.</p>
    </section>
);

const BenefitsList = () => (
    <>
        <List>
            <TextItem left img={monitoring} headingLevel='h2' title={['Higher Level', 'of Monitoring']}>
                {'Being based on Apache Camel Guaranteed Delivery Layer has a good level of monitoring and doesn\'t require code creation from a developer.'}
            </TextItem>
            <TextItem img={abstraction} headingLevel='h2' title={['Higher Level', 'of Abstraction']}>
                Guaranteed Delivery Layer delivers a ready-to-use solution for guaranteed delivery which is easy to be configured for a specific integration task.
            </TextItem>
            <TextItem left img={quickSetup} headingLevel='h2' title="Quick Setup">
                {'Compared to JMS-based solutions, Guaranteed Delivery Layer doesn\'t require complicated installing, maintaining, and monitoring of JMS instances.'}
            </TextItem>
        </List>
        <Button className='button-blue mx-auto' onClick={() => window.open('https://github.com/Artezio/ART-GDL')} value='Find out more'/>
    </>
);

const Features = () => (
    <section id='features'>
        <h1 className='features__header text-center mx-auto'>Guaranteed Delivery Layer Features</h1>
        <Row>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem headingLevel="h3" title="Buffer Incoming Messages and Manage Load of the Receiver System">
                    Guaranteed Delivery Layer buffers incoming messages in case of an overload of the receiver system and provides an ability to manage the load of the system so that is runs without delays.
                </TextItem>
            </Col>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem left headingLevel="h3" title="Set up Schedule of Data Delivery Attempts">
                    With the help of Guaranteed Delivery Layer, you can define intervals between data delivery attempts to keep track of the whole data delivery process.
                </TextItem>
            </Col>
        </Row>

        <Row>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem headingLevel="h3" title="Define Delivery Intervals">
                    State date and time when the message will be delivered to the receiver system and identify the time when it will be considered as overdue.
                </TextItem>
            </Col>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem left headingLevel="h3" title="Set the Number of Delivery Attempts">
                    Guaranteed Delivery Layer provides an ability to identify the number of delivery attempts after which it will be considered as overdue.
                </TextItem>
            </Col>
        </Row>

        <Row>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem headingLevel="h3" title="Create Logical Layered Message Queues">
                    Thanks to FIFO method of message processing, ART-GDL lets create complex tree-structured sequences of messages.
                </TextItem>
            </Col>
            <Col xs={{size: 12}} lg={{size: 6}} className="features__content px-xs-0 px-md-5">
                <TextItem left headingLevel="h3" title="Check Duplication">
                    The solution ensures duplication avoidance in cluster work by marking up messages with a unique incoming string identifier.
                </TextItem>
            </Col>
        </Row>
        <div className='features__button d-flex justify-content-center'>
            <Button icon={github} onClick={() => window.open('https://github.com/Artezio/ART-GDL')} value='Download from GitHub'/>
        </div>
    </section>
);

const Info = () => (
    <List>
        <TextItem left img={license} headingLevel='h2' title={'License'}>
            ART-GDL is distributed under <a href='http://www.apache.org/licenses/LICENSE-2.0'>Apache License 2.0</a> open source license.
        </TextItem>
        <section id='technology' className='stack text-center'>
            <h1>Technology Stack</h1>
            <img className='img-fluid' src={techStack} alt='technology stack' />
        </section>
        <section id='contributors' className='contributor text-center mx-auto'>
            <h1 className='contributor__header'>Our sponsor</h1>
            <p>Guaranteed Delivery Layer was initially implemented by Java Developer Olesya Shulyaeva. The development of the solution is sponsored by Artezio.</p>
            <p><a href='https://www.artezio.com/'>Artezio</a> is an ISO 9001:2015 certified software development company.
                Since establishment in 2000, Artezio has completed over 1000 projects for its international
                corporate customer base, thus the Company has become one of the key players on the European/US
                software development outsourcing market. Besides, Artezio encourages the development of new
                projects and initiatives inside the company striving to achieve excellence in software development.
            </p>
            <img className='img-fluid' src={artLogo} alt='artezio logo' />
        </section>
    </List>
);

const Footer = () => (
    <section id='contacts'>
        <Row>
            <Col className='footer__sponsored col-2 d-none d-md-block '>Sponsored by Artezio</Col>
            <Col className='col'>
                <div className='footer__button d-flex justify-content-center'>
                    <Button icon={github} onClick={() => window.open('https://github.com/Artezio/ART-GDL')} value='Download from GitHub'/>
                </div>
                <div className='footer__rights text-center'>
                    <span>2023 Artezio LLC, All rights reserved</span>
                </div>
            </Col>
            <Col className='footer__url col-2 d-none d-md-block'>artezio.com</Col>
        </Row>
    </section>
);

export default Main;