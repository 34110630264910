import React from 'react';
import './App.scss';
import Main from './containers/Main/Main';


function App() {
    return (
        <Main/>
    );
}

export default App;
