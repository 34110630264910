import React from 'react';
import PropTypes from 'prop-types';
import './Box.scss';

const Box = props => {
    const {bgColor, paddingTop, paddingBottom, children, className} = props;
    const classes = [];
    classes.push('box');
    if (bgColor) {
        classes.push('box-' + bgColor);
    }

    if (className) {
        className.split(' ').forEach(c => classes.push(c));
    }

    return (
        <div className={classes.join(' ')} style={{paddingTop: paddingTop ? paddingTop : '', paddingBottom: paddingBottom ? paddingBottom : ''}} >
            {children}
        </div>
    );
};

Box.propTypes = {
    children: PropTypes.node.isRequired,
    bgColor: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    className: PropTypes.string
};

export default Box;