import React from 'react';
import './Button.scss';
import PropTypes from 'prop-types';

const Button = props => {
    const {icon, className, onClick, value} = props;
    const classes = [];
    classes.push('button');
    if (className) {
        className.split(' ').forEach(c => classes.push(c));
    }

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            {
                icon &&
                <img src={icon} className='icon' alt='icon'/>
            }
            <div className='value'>{value}</div>
        </div>
    );
};

Button.propTypes = {
    icon: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default Button;
