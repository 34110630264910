import React from "react";
import logo from "../../../assets/png/logo.png";
import {
    Collapse,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink
} from "reactstrap";
import "./Header.scss";
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class Header extends React.Component{

    state = {
        menuShowed: false
    };

    toggleMenu = () => this.setState(prevState => ({
        menuShowed: !prevState.menuShowed
    }));

    render() {
        return (
            <div className="header text-nowrap">
                <Navbar color="faded" light expand="lg" className="navBar">
                    <img className="img-fluid" src={logo} alt="logo"/>

                    <NavbarToggler onClick={this.toggleMenu} />
                    <Collapse isOpen={this.state.menuShowed} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="navItem">
                                <AnchorLink offset='100' className="navBar__navLink nav-link" href="#whatis">What is it?</AnchorLink>
                            </NavItem>
                            <NavItem className="navItem">
                                <AnchorLink offset='100' className="navBar__navLink nav-link" href="#features">Features</AnchorLink>
                            </NavItem>
                            <NavItem className="navItem">
                                <AnchorLink offset='100' className="navBar__navLink nav-link" href="#technology">Technology</AnchorLink>
                            </NavItem>
                            <NavItem className="navItem">
                                <AnchorLink offset='100' className="navBar__navLink nav-link" href="#contributors">Contributors</AnchorLink>
                            </NavItem>
                            <NavItem className="navItem">
                                <AnchorLink offset='100' className="navBar__navLink nav-link" href="#contacts">Contacts</AnchorLink>
                            </NavItem>
                            <div className="menuDivider"/>
                            <NavItem className="navItem">
                                <NavLink offset='100' className="navBar__navLink navBar__navLink_theme_download" href="https://github.com/Artezio/ART-GDL">Download</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );

    }
}