import React from 'react';
import PropTypes from 'prop-types';
import './List.scss';

const List = (props) => (
    <ul className='list'>
        {React.Children.map(props.children, child => {
            return (
                <li>
                    {child}
                </li>
            );
        })}
    </ul>
);

List.propTypes = {
    children: PropTypes.node.isRequired
};

export default List;