import * as React from 'react';
import './TextItem.scss';
import {Col, Row} from 'reactstrap';
import PropTypes from 'prop-types';


export default class TextItem extends React.Component {

    render() {
        const validHeadingLevels = ['h1', 'h2', 'h3'];
        const {title, headingLevel, img, left} = this.props;
        const HeadingTag = validHeadingLevels.includes(headingLevel) ? headingLevel : 'p';
        const HeadingSection = title instanceof Array ? title.map(t => (<HeadingTag key={t}>{t}</HeadingTag>)) : <HeadingTag>{title}</HeadingTag>;
        const columnTextDirection = left ? 'text-right' : 'text-left';
        const textColumnSize = img ? 6 : 12;
        const conditionalPadding = img ? 'pt-3' : '';

        return (
            <Row className="align-items-center">
                {
                    img &&
                    <Col xs={{size: 6, offset: 3, order: -1}} md={{size: 6, offset: 0, order: left ? 1 : 3}} className={columnTextDirection}>
                        <img className="img-fluid" src={img} alt="img"/>
                    </Col>
                }

                <Col xs={{size: 12, order: 2}} md={{size: textColumnSize}} className={conditionalPadding}>
                    <div className={'text-item ml-auto mr-auto ' + (left ? ' ml-lg-0' : ' mr-lg-0')}>
                        <div className="text-item__header">
                            {HeadingSection}
                        </div>
                        <p>{this.props.children}</p>
                    </div>
                </Col>
            </Row>

        );
    }

}

TextItem.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
    headingLevel: PropTypes.string,
    img: PropTypes.object,
    left: PropTypes.bool
};